<template>
  <section id="quienes">
    <div v-if="useMargin" class="mt-100"></div>
    <div class="py-12"></div>
    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3 ajustRespTitle">QUIENES SOMOS</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive class="mx-auto title font-weight-light mb-8 ajustResp" max-width="720">
        Somos una empresa enfocados en resolver las necesidades de
        transportes de nuestros clientes. Nuestra especialidad es el
        movimiento integral de maquinarias y vehículos. <br>
        Contamos con modernos equipos con altos estándares de
        seguridad diseñados para cada necesidad. <br>
        Todos nuestro equipos cuentan con sistema de monitoreo en
        tiempo real (GPS) por lo que nuestros clientes pueden solicitar la
        ubicación exacta de sus bienes como la velocidad de
        desplazamiento de cada equipo, además contamos con sistema
        de cámaras de seguridad y seguros de carga y responsabilidad civil. <br>
        Nuestros operadores son altamente calificados y capacitados
        periódicamente con el fin de estar a la vanguardia de cada
        requerimiento y necesidades de nuestros clientes.
      </v-responsive>
      <div class="d-flex justify-center ma-4">
        <v-img contain max-height="240" src="/assets/logoSM.png"></v-img>
      </div>
      <div></div>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>
export default {
  created() {
  },
  props: {
    useMargin: {
      default: true,
      type: Boolean
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {
  
}
</style>

<template>
  <section id="" class="grey lighten-3">
    <div v-if="useMargin" class="mt-100"></div>
    <div class="py-12"></div>
    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Nuestros Equipos</h2>
      <v-responsive class="mx-auto mb-6 mt-6" width="75">
        <v-divider class="mb-1" color="#CCC"></v-divider>

        <v-divider color="#CCC"></v-divider>
      </v-responsive>
      <div v-if="all">
        <div v-for="({  img, img2, title, text, t1,t2,t3,t4,t5,t6 }, i) in features" :key="i">
          <v-row>
            <v-col cols="12" md="12">
              <v-card class="pa-2" color="grey lighten-5" flat>
                <v-card-title class="justify-center font-weight-black text-uppercase" style="word-break: break-word">
                  {{title}}</v-card-title>
                <v-card-text class="subtitle-1">{{text}}
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
              <v-carousel hide-delimiters height="100%" :show-arrows="img2 != undefined">
                <v-carousel-item>
                  <v-card class="pa-9" color="grey lighten-5" flat>
                    <v-theme-provider dark>
                      <div class="d-flex justify-center">
                        <v-img contain class="elevation-3 imgCarr" v-bind:src="'/assets/'+img">
                        </v-img>
                      </div>
                    </v-theme-provider>
                  </v-card>
                </v-carousel-item>
                <v-carousel-item v-if="img2 != undefined">
                  <v-card class="pa-9" color="grey lighten-5" flat>
                    <v-theme-provider dark>
                      <div class="d-flex justify-center">
                        <v-img contain class="elevation-3 imgCarr" v-bind:src="'/assets/'+img2">
                        </v-img>
                      </div>
                    </v-theme-provider>
                  </v-card>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">

              <v-card class="pa-2" color="grey lighten-5" flat>
                <v-simple-table class="elevation-2">
                  <template v-slot:default>
                    <tbody class="text-left">
                      <tr>
                        <td class="font-weight-black">{{t1}}</td>
                        <td>{{t2}}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-black">{{t3}}</td>
                        <td>{{t4}}</td>
                      </tr>
                      <tr v-if="t5 != undefined">
                        <td class="font-weight-black">{{t5}}</td>
                        <td>{{t6}}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
          </v-row>
          <v-responsive class="mx-auto mb-6 mt-6" width="75">
            <v-divider class="mb-1" color="#fcba03"></v-divider>

            <v-divider color="#fcba03"></v-divider>
          </v-responsive>
        </div>
      </div>
      <div v-else>
        <v-row>
          <v-col cols="12" md="12">
            <v-card class="pa-2" color="grey lighten-5" flat>
              <v-card-title class="justify-center font-weight-black text-uppercase" style="word-break: break-word">{{features[select].title}}
              </v-card-title>
              <v-card-text class="subtitle-1">{{features[select].text}}
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="12">
            <v-carousel hide-delimiters height="100%" :show-arrows="features[select].img2 != undefined">
              <v-carousel-item>
                <v-card class="pa-9" color="grey lighten-5" flat>
                  <v-theme-provider dark>
                    <div class="d-flex justify-center">
                      <v-img contain class="elevation-3 imgCarr" v-bind:src="'/assets/'+features[select].img">
                      </v-img>
                    </div>
                  </v-theme-provider>
                </v-card>
              </v-carousel-item>
              <v-carousel-item v-if="features[select].img2 != undefined">
                <v-card class="pa-9" color="grey lighten-5" flat>
                  <v-theme-provider dark>
                    <div class="d-flex justify-center">
                      <v-img contain class="elevation-3 imgCarr"
                        v-bind:src="'/assets/'+features[select].img2">
                      </v-img>
                    </div>
                  </v-theme-provider>
                </v-card>
              </v-carousel-item>
            </v-carousel>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="12">
            <v-card class="pa-2" color="grey lighten-5" flat>
              <v-simple-table class="elevation-2">
                <template v-slot:default>
                  <tbody class="text-left">
                    <tr>
                      <td class="font-weight-black">{{features[select].t1}}</td>
                      <td>{{features[select].t2}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-black">{{features[select].t3}}</td>
                      <td>{{features[select].t4}}</td>
                    </tr>
                    <tr>
                      <td class="font-weight-black">{{features[select].t5}}</td>
                      <td>{{features[select].t6}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-card>
          </v-col>
          <v-col class="mx-auto" cols="auto">
            <v-btn color="#fcba03" x-large @click="verTodos()">
              Ver todos los equipos
            </v-btn>
          </v-col>
        </v-row>
      </div>
      <!-- <h2 class="display-2 font-weight-bold mb-3">ABOUT ME 2</h2>

        <v-responsive class="mx-auto mb-8" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-responsive class="mx-auto title font-weight-light mb-8" max-width="720">
          Vuetify is the #1 component library for Vue.js and has been in active development since 2016. The goal of the
          project is to provide users with everything that is needed to build rich and engaging web applications using
          the
          Material Design specification. It accomplishes that with a consistent update cycle, Long-term Support (LTS)
          for
          previous versions, responsive community engagement, a vast ecosystem of resources and a dedication to quality
          components.
        </v-responsive>

        <v-avatar class="elevation-12 mb-12" size="128">
          <v-img src="https://cdn.vuetifyjs.com/images/john.png"></v-img>
        </v-avatar>

        <div></div>

        <v-btn color="grey" href="https://vuetifyjs.com" outlined large>
          <span class="grey--text text--darken-1 font-weight-bold">
            Vuetify Documentation
          </span>
        </v-btn> -->
    </v-container>
  </section>
</template>

<script>
import router from "../router";
export default {
  created() {
    if (this.$route.params.equipo != "all") {
      this.all = false
      this.equipo = this.$route.params.equipo
      switch (this.$route.params.equipo) {
        case "e1":
          this.select = 0
          break;
        case "e2":
          this.select = 1
          break;
        case "e3":
          this.select = 2
          break;
        case "e4":
          this.select = 3
          break;
        case "e5":
          this.select = 4
          break;
      }
    } else {
      this.all = true
    }
  },
  methods: {
    verTodos() {
      this.all = true
      router.push("/servicios/all").catch(() => { });
    }
  },
  props: {
    useMargin: {
      default: true,
      type: Boolean
    }
  },
  data: function () {
    return {
      all: true,
      equipo: "",
      selec: 0,
      features: [
        {
          img: 'volvo330-1.png',
          img2: 'volvo330-2.png',
          cod: 'e1',
          title: 'Volvo VM 330',
          text: 'Camión volvo modelo VM 330 año 2022 caja automatizada, configuración de ejes 8x2 bidireccional con levante y puente de apoyo.',
          t1: 'Cama Hidráulica Multipropósito',
          t2: '18 toneladas',
          t3: 'Dimensiones Largo',
          t4: '10 metros (9.5 útil)',
          t5: 'Dimensiones Ancho',
          t6: '2.60 metros',
        },
        {
          img: 'fordcargo-1.png',
          img2: 'fordcargo-2.png',
          cod: 'e2',
          title: 'Ford cargo 330',
          text: 'Camión Ford cargo 330 año 2015, configuración de ejes 6x2 con eje de apoyo con levante.',
          t1: 'Cama Hidráulica Multipropósito',
          t2: '12 toneladas',
          t3: 'Dimensiones Largo',
          t4: '8.5 metros (8 metros útil)',
          t5: 'Dimensiones Ancho',
          t6: '2.50 metros',
        },
        {
          img: 'vw-1.png',
          img2: 'vw-2.png',
          cod: 'e3',
          title: 'Volkswagen Constellation 17230',
          text: 'Camión Volkswagen modelo Constellation 17230 año 2.018, configuración de ejes 6x2.',
          t1: 'Cama Hidráulica Multipropósito',
          t2: '10 toneladas',
          t3: 'Dimensiones Largo',
          t4: '8 metros (7.5 útil)',
          t5: 'Dimensiones Ancho',
          t6: '2.50 metros',
        },
        {
          img: 'npr-1.png',
          img2: 'npr-2.png',
          cod: 'e4',
          title: 'Chevrolet NPR 816',
          text: 'Camión Chevrolet NPR 816 año 2021, configuración de ejes 4x2.',
          t1: 'Cama Hidráulica Multipropósito',
          t2: '5 toneladas',
          t3: 'Dimensiones Largo',
          t4: '5.80 metros',
          t5: 'Dimensiones Ancho',
          t6: '2.20 metros',
        },
        {
          img: 'yale-1.png',
          cod: 'e5',
          title: 'Grúa Horquilla',
          text: 'Grúa Horquilla marca YALE año 2015.',
          t1: 'Capacidad de carga',
          t2: '2.7 toneladas',
          t3: 'Mástil bajo',
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.imgCarr {
  max-width: 600px
}

@media only screen and (max-width: 600px) {
  .imgCarr {
    max-width: 300px
  }
  
}
</style>

<template>
  <v-app>
    <v-app-bar app color="#fcba03" height="100" id="inicio">
      <v-img max-height="100" max-width="200" src="/assets/logoSM.png"></v-img>
      <!-- <v-toolbar-title color="#6b4f02" class="hiddenXS">
        GRUAS NANJARI
      </v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-app-bar-nav-icon color="white" @click="drawer = true" class="d-sm-none"></v-app-bar-nav-icon>
      <!-- <v-tabs v-model="tab" right class="d-none d-sm-flex">
        <v-tabs-slider color="yellow"></v-tabs-slider>

        <v-tab v-for="item in items" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs> -->
      <v-card outlined flat>
        <v-tabs v-model="tab" color="#6b4f02" class="d-none d-sm-flex" right background-color="#fcba03">
          <v-tab v-for="(item) in items" :key="item.key" @click="irA(item.key)">
            {{ item.name }}
          </v-tab>
          <v-tab @click="irA('it')">
            <v-icon>mdi-instagram</v-icon>
          </v-tab>
          <v-tab @click="irA('fc')">
            <v-icon>mdi-facebook</v-icon>
          </v-tab>
        </v-tabs>
      </v-card>
    </v-app-bar>
    <v-navigation-drawer app v-model="drawer" temporary>
      <v-list nav>
        <v-subheader>Menu</v-subheader>
        <v-list-item-group v-model="tab">
          <v-list-item v-for="(item,index) in items" :key="item.key" :class="tab == index?'activemenu':''">
            <v-list-item-title @click="irA(item.key)">{{ item.name }}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="irA('it')">
            <v-icon>mdi-instagram</v-icon>
          </v-list-item>
          <v-list-item @click="irA('fc')">
            <v-icon>mdi-facebook</v-icon>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-navigation-drawer>
    <router-view />
    <v-footer class="justify-center" color="#292929" height="100">
      <div class="title font-weight-light grey--text text--lighten-1 text-center">
        &copy; {{ (new Date()).getFullYear() }} — GRUAS NANJARI
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import router from "./router";

export default {
  name: 'App',

  mounted() {
    this.$root.$on('changetab', (tab) => {
      this.tab = tab
    })
    switch (this.$route.name) {
      case "inicio":
        this.tab = 0
        break;
      case "contacto":
        this.tab = 4
        break;
      case "quienessomos":
        this.tab = 2
        break;
      case "servicios":
        this.tab = 1
        break;
      case "galeria":
        this.tab = 3
        break;
    }
  },

  components: {
  },

  data: function () {
    return {
      drawer: false,
      tab: String,
      items: [
        { name: 'INICIO', key: "inicio" }, { name: 'EQUIPOS', key: "servicios" }, { name: 'QUIENES SOMOS', key: "quienes" }, { name: 'GALERIA', key: "galeria" }, { name: 'CONTACTO', key: "contacto" },
      ],
    };
  },
  methods: {
    irA(key) {
      switch (key) {
        case "inicio":
          router.push("/").catch(() => { });
          break;
        case "contacto":
          router.push("/contacto").catch(() => { });
          break;
        case "quienes":
          router.push("/quienessomos").catch(() => { });
          break;
        case "servicios":
          router.push("/servicios/all").catch(() => { });
          break;
        case "galeria":
          router.push("/galeria").catch(() => { });
          break;
        case "fc":
          if(window.innerWidth <= 600)
            window.open('https://facebook.openinapp.co/profilephp-582', '_blank')
          else
            window.open('https://www.facebook.com/profile.php?id=100083394961534', '_blank');
          break;
        case "it":
          window.open('https://instagram.com/rodrigo.nanjari?igshid=YmMyMTA2M2Y=', '_blank');
          break;
      }
    }
  },
};
</script>

<style >
#app {
  font-family: Verdana, sans-serif;
  font-size: 12px;
}

.theme--light.v-sheet--outlined {
  border: none !important;
}

#app .imgHome {
  min-height: calc(100vh - 100px);
}

#app .activemenu {
  border-right: 5px solid #fcba03 !important
}

#app .mt-100 {
  margin-top: 30px;
}

.silentbox-item {
  max-height: 145px;
  border-radius: 0.5rem;
  margin-right: 1rem;
  overflow: hidden;
}

.silentbox-item:hover {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.42);
  -moz-box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.42);
  box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.42);
}

#silentbox-overlay__close-button {
  margin-right: 20px;
}

@media only screen and (max-width: 600px) {
  #app .ajustResp {
    font-size: 15px !important;
  }

  #silentbox-overlay__close-button {
    margin-right: 10px;
  }

  #app h2 {
    font-size: 30px !important;
  }

  #app .py-12 {
    padding-top: 15px !important;
    padding-bottom: 15px !important;
  }

  #app .imgHome {
    min-height: calc(40vh - 100px);
  }

  #app .align-self-end {
    display: none;
  }

  #app .mt-100 {
    margin-top: 70px;
  }
}
</style>

import Vue from "vue";
import VueRouter from "vue-router";
import MyHome from "../components/MyHome";
import MyServicios from "../components/MyServicios";
import MyQuienes from "../components/MyQuienes";
import MyContacto from "../components/MyContacto";
import MyGaleria from "../components/MyGaleria";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "inicio",
    component: MyHome,
  },
  {
    path: "/servicios/:equipo",
    name: "servicios",
    component: MyServicios,
  },
  {
    path: "/contacto",
    name: "contacto",
    component: MyContacto,
  },
  {
    path: "/quienessomos",
    name: "quienessomos",
    component: MyQuienes,
  },
  {
    path: "/galeria",
    name: "galeria",
    component: MyGaleria,
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});



export default router;
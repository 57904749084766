<template>
  <v-main>
    <section id="hero">
      <v-row no-gutters>
        <v-img class="imgHome" src="https://gruasnanjari.cl/assets/camionhome.jpg">
          <v-theme-provider dark>
            <v-container fill-height>
              <v-row align="center" class="white--text mx-auto" justify="center">
                <!-- <v-col class="white--text text-center" cols="12" tag="h1">
                  <span :class="[$vuetify.breakpoint.smAndDown ? 'display-1' : 'display-2']" class="font-weight-light">
                    GRUAS
                  </span>

                  <br>

                  <span :class="[$vuetify.breakpoint.smAndDown ? 'display-3': 'display-4']" class="font-weight-black">
                    NANJARI
                  </span>

                </v-col> -->

                <v-btn class="align-self-end" fab outlined @click="$vuetify.goTo('#contacto')">
                  <v-icon>mdi-chevron-double-down</v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-theme-provider>
        </v-img>
      </v-row>
    </section>

    <MyQuienes :useMargin=false></MyQuienes>
    <!-- <div class="py-12"></div> -->

    <section id="servicios" class="grey lighten-3">
      <div class="py-12"></div>

      <v-container class="text-center">
        <h2 class="display-2 font-weight-bold mb-3">EQUIPOS</h2>

        <v-responsive class="mx-auto mb-12" width="56">
          <v-divider class="mb-1"></v-divider>

          <v-divider></v-divider>
        </v-responsive>

        <v-row>
          <v-col v-for="({  cod, img, title, text }, i) in features" :key="i" cols="12" md="4">
            <v-card class="py-12 px-4" color="grey lighten-5" flat>
              <v-theme-provider dark>
                <div class="d-flex justify-center">
                  <v-img contain max-width="250" class="elevation-2" v-bind:src="'/assets/'+img">
                  </v-img>
                </div>
              </v-theme-provider>

              <v-card-title class="justify-center font-weight-black text-uppercase" v-text="title"></v-card-title>
              <v-card-text class="subtitle-1" v-text="text">
              </v-card-text>
              <v-btn color="#fcba03" @click="verDetalle(cod)">
                Ver detalle
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <div class="py-12"></div>
    </section>
    <MyContacto :useMargin=false></MyContacto>
  </v-main>
</template>

<script>
import MyQuienes from "./MyQuienes";
import MyContacto from "./MyContacto";
import router from "../router";
export default {
  data: function () {
    return {
      features: [
        {
          img: 'volvo330-1.png',
          cod: 'e1',
          title: 'Volvo VM 330',
          text: 'Camión volvo modelo VM 330 año 2022 caja automatizada, configuración de ejes 8x2 bidireccional con levante y puente de apoyo.',
        },
        {
          img: 'fordcargo-1.png',
          cod: 'e2',
          title: 'Ford cargo 330',
          text: 'Camión Ford cargo 330 año 2015, configuración de ejes 6x2 con eje de apoyo con levante.',
        },
        {
          img: 'vw-1.png',
          cod: 'e3',
          title: 'Volkswagen Constellation 17230',
          text: 'Camión Volkswagen modelo Constellation 17230 año 2.018, configuración de ejes 6x2.',
        },
        {
          img: 'npr-1.png',
          cod: 'e4',
          title: 'Chevrolet NPR 816',
          text: 'Camión Chevrolet NPR 816 año 2021, configuración de ejes 4x2.',
        },
        {
          img: 'yale-1.png',
          cod: 'e5',
          title: 'Grúa Horquilla',
          text: 'Grúa Horquilla marca YALE año 2015.',
        },
      ],
    };
  },
  components: {
    MyQuienes,
    MyContacto,
  },
  methods: {
    verDetalle(cod) {
      this.$root.$emit("changetab", 1);
      router.push("/servicios/"+cod).catch(() => { });
    }
  },

};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <section id="quienes">
    <div v-if="useMargin" class="mt-100"></div>
    <div class="py-12"></div>
    <v-container class="text-center">
      <h2 class="display-2 font-weight-bold mb-3 ajustRespTitle">GALERIA</h2>

      <v-responsive class="mx-auto mb-8" width="56">
        <v-divider class="mb-1"></v-divider>

        <v-divider></v-divider>
      </v-responsive>

      <v-responsive class="mx-auto title font-weight-light mb-8 ajustResp">
        <!-- <img class="image" v-for="(image, i) in images" :src="image.url" :key="i" @click="index = i">
        <vue-gallery-slideshow :images="images" :index="index" @close="index = null"></vue-gallery-slideshow> -->
        <silent-box :gallery="images"></silent-box>
      </v-responsive>

      <div></div>
    </v-container>
    <div class="py-12"></div>
  </section>
</template>

<script>

export default {
  components: {

  },
  created() {
    let imagenes = []
      for (let i = 1; i <= 55; i++) {
        let json = { src: 'https://gruasnanjari.cl/assets/gal/gn (' + i + ').jpeg', thumbnailWidth: '220px', alt: '1cvbvcbvcb' }
        imagenes.push(json)
    }
    this.images= imagenes
  },
  props: {
    useMargin: {
      default: true,
      type: Boolean
    },
  },
  methods: {
    getImages() {
      let images = []
      for (let i = 1; i <= 55; i++) {
        let json = { src: 'https://gruasnanjari.cl/assets/gal/gn (' + i + ').jpeg', description: 'asd', thumbnailWidth: '220px', alt: '1cvbvcbvcb' }
        images.push(json)
      }
      return images
    }
  },
  data: function () {
    return {
    images: []
  }
  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@media only screen and (max-width: 600px) {}
</style>

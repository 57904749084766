<template>
  <v-sheet id="contacto" color="#333333" dark tag="section" tile>
    <div v-if="useMargin" class="mt-100"></div>
    <div class="py-12"></div>
    <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contacto</h2>
      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1"></v-divider>
        <v-divider></v-divider>
      </v-responsive>
    <section id="stats">
      <v-parallax :height="$vuetify.breakpoint.smAndDown ? 400 : 500"
        src="https://www.baseoutsourcing.com/wp-content/uploads/2020/05/contactusheader.jpg">
        <v-container fill-height>
          <v-row class="">
            <v-col cols="12" md="5">
              <h5 class="display-2 font-weight-bold mb-3 text-center sizeresp">Contáctate a través de nuestro WhatsApp
              </h5>
              <!-- <span class="d-inline-block">
        <v-icon class="mb-1" color="green" large>mdi-whatsapp</v-icon> <span @click="towsp()"
          class="text-decoration-underline font-italic row-pointer">+569 89057106</span>
      </span> -->
              <div class="py-8"></div>
              <v-row align="center" justify="space-around">
                <v-btn color="success" x-large rounded class="texttam pa-10" @click="towsp()">
                  <b>
                    <v-icon left large>
                      mdi-whatsapp
                    </v-icon>
                  </b>
                  +569 89057106
                </v-btn>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-parallax>
    </section>
    <v-container>
      <!-- <h2 class="display-2 font-weight-bold mb-3 text-uppercase text-center">Contacto</h2>
      <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1"></v-divider>
        <v-divider></v-divider>
      </v-responsive>
      <h5 class="display-2 font-weight-bold mb-3 text-center sizeresp">Contáctate a través de nuestro WhatsApp</h5>
      <div class="py-8"></div>
      <v-row align="center" justify="space-around">
        <v-btn color="success" x-large rounded class="texttam pa-10">
          <b>
            <v-icon left large>
              mdi-whatsapp
            </v-icon>
          </b>
          +569 89057106
        </v-btn>
      </v-row> -->

      
      <!-- o
        llenando el formulario -->
      <!-- <v-responsive class="mx-auto mb-12" width="56">
        <v-divider class="mb-1"></v-divider>
        <v-divider></v-divider>
      </v-responsive>
      <v-theme-provider light>
        <v-row>
          <v-col cols="12">
            <v-text-field flat label="Nombre*" solo></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field flat label="Email*" solo></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field flat label="Asunto*" solo></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-textarea flat label="Mensaje*" solo></v-textarea>
          </v-col>

          <v-col class="mx-auto" cols="auto">
            <v-btn color="#fcba03" x-large>
              Enviar
            </v-btn>
          </v-col>
        </v-row>
      </v-theme-provider> -->
      <div class="py-12"></div>
    </v-container>
  </v-sheet>
</template>

<script>
export default {
  created() {
  },
  methods: {
    towsp() {
      window.open("https://wa.me/56989057106", '_blank');
    }
  },
  props: {
    useMargin: {
      default: true,
      type: Boolean
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.row-pointer {
  cursor: pointer;
}

.texttam {
  font-size: 32px;
}

@media only screen and (max-width: 600px) {


  .sizeresp {
    font-size: 30px !important;
  }
  .texttam {
  font-size: 25px;
}
}
</style>
